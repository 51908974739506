import React from 'react'
import utils from '../utils'
import moment from 'moment'
import _ from 'lodash'

interface DataTableFormatterProps {
  cell: any
  row: any
}

export const defaultDateFormat = (date: string | Date | undefined) => {
  if (date === null) return 'N/A'
  const val = moment(date).utc()

  if (val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return val.format('YYYY-MM-DD')
}

export const moneyFormat = (val: string | Number) => {
  let value = _.toNumber(val)
  if (value !== 0) {
    value = value / 100
  }

  return priceFormat(value)
}

export const priceFormat = (val: string | Number) => {
  const value = _.toNumber(val)

  return `$${value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
}

// data table formatters
export const defaultDateFormatter = ({
  cell,
  row,
}: DataTableFormatterProps) => {
  const val = moment(cell).utc()

  if (!val.isValid() || val.diff(utils.NULL_DATE) === 0) return 'N/A'

  return <span>{val.format('MM/DD/YYYY')}</span>
}

export const moneyFormatter = ({ cell, row }: DataTableFormatterProps) => {
  return moneyFormat(cell)
}

export const priceFormatter = ({ cell, row }: DataTableFormatterProps) => {
  return priceFormat(cell)
}

export const emailLinkFormatter = (cell: any) => {
  const mailto = `mailto:${cell}`
  return (
    <a href={mailto} id="view-link">
      {cell}
    </a>
  )
}

export const nameFormatter = (
  firstName: string,
  middleName: string,
  lastName: string,
  suffix: string
): string => {
  const mid = middleName ? `${middleName} ` : ''
  const suf = suffix ? ` ${suffix}` : ''
  return `${firstName} ${mid}${lastName}${suf}`
}

export const phoneFormatter = (cell: any): string => {
  const value: string = _.toString(cell)
  const areaCode = value.substring(0, 3)
  const firstPart = value.substring(3, 6)
  const secondPart = value.substring(6, 10)

  if (!areaCode || !firstPart || !secondPart) return ''

  return `(${areaCode})-${firstPart}-${secondPart}`
}
