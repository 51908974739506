// @ts-ignore
import React from 'react'
// @ts-ignore
import { createRoot } from 'react-dom/client'
// @ts-ignore
import axios from 'axios'
// @ts-ignore
import { Typography, Snackbar, Button } from '@material-ui/core'
// @ts-ignore
import Alert from '@material-ui/lab/Alert'

const delay = 30000 // check every 30 seconds
let etagAtLoad: any = null

export default function checkRelease(count = 0) {
  axios.head(window.location.origin).then(({ headers }: any) => {
    // axios *should* normalize the headers to all lowercase: https://axios-http.com/docs/res_schema
    const etag = `${headers['etag']}`.replace('W/', '').replace(/"/g, '')
    console.debug(`[checkRelease] etag: ${etag}; count: ${count}`)

    // first run of this function (immediately upon browser load): store the etag
    if (!etagAtLoad) {
      etagAtLoad = etag
      setTimeout(() => {
        checkRelease(count + 1)
      }, delay)
      return
    }

    // if the just-checked etag doesn't match the etag we found on the first check,
    // fire a notification. Notably, once we get here, this checkRelease will STOP
    // future checks.
    if (etagAtLoad !== etag) {
      let $el: any = document.createElement('div')
      document.body.appendChild($el)

      const ignore = () => {
        $el._checkReleaseRoot.unmount()
        document.body.removeChild($el)
        $el = null
      }

      const refresh = () => {
        window.location.reload()
      }

      const component = (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ boxShadow: '0 0 20px rgba(0,0,0,0.25)' }}>
          <Alert
            severity="warning"
            onClose={() => {}}
            action={
              <>
                <Button
                  style={{ whiteSpace: 'nowrap' }}
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={ignore}>
                  Ignore
                </Button>
                &nbsp;&nbsp;
                <Button
                  style={{ whiteSpace: 'nowrap' }}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={refresh}>
                  Refresh Now
                </Button>
              </>
            }>
            <Typography variant="body1">
              A new version of TeamZERO is available simply by refreshing the
              page.
              <small style={{ display: 'block', paddingTop: '0.35rem' }}>
                Notice: any unsaved changes will be discarded by refreshing. You
                may still interact with the page to save ongoing work.
              </small>
            </Typography>
          </Alert>
        </Snackbar>
      )

      // since JS has no rules and you can just attach properties to anything ¯\_(ツ)_/¯
      $el._checkReleaseRoot = createRoot($el)
      $el._checkReleaseRoot.render(component)
      return
    }

    // the just-checked etag didn't change from the etag at load; do nothing except
    // queue another check after <delay>
    setTimeout(() => {
      checkRelease(count + 1)
    }, delay)
  })
}
