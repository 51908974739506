import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { RootComponent } from './styledComponents'
import { memberActions, TermParams } from '../../redux/member/memberActions'
import { useStateValue } from '../../redux'
import {
  MemberContent,
  MembershipColumn,
  MemberListItem,
  MembershipHeader,
  MembershipCard,
  IDCardColumn,
} from './styledComponents'
import {
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  CircularProgress,
  Fade,
  Typography,
} from '@material-ui/core'

import { EditButton } from '../../components/Buttons'
import { ScrollToTop } from '../../components/ScrollToTop'
import { isNullDate } from '../../utils'
// @todo move other formatters from utils => formatters
import {
  nameFormatter,
  emailLinkFormatter,
  phoneFormatter,
  defaultDateFormat,
} from '../../formatters'
import PrintMemberIdCard from '../../components/PrintMemberIdCard'
import DialogSetDeactivationDate from '../../components/Dialogs/SetDeactivationDate'
import { useAppPerms } from '../../AppPermissions'
import EditMemberDetailsDialog from './dialogEditDetails'
import { TITLE_TEMPLATE } from '../../utils'
import { useEmployerIDFromRoute } from '../../hooks'
import Tooltip from '../../components/Tooltip'
import { eligibilityActions } from '../../redux/eligibility/eligibilityActions'
const { getEligibilitySubsetHandles } = eligibilityActions

interface MemberDetailProps {
  match: any // probs a nice type in react-router
  history: any
  location: any
}

interface Member {
  Address?: any
  AddressId?: number
  Dob: string
  Email: string
  EmployerMemberships?: EmployerMembership[]
  FirstName: string
  Honorific: string
  ID: number
  LastName: string
  MiddleName: string
  Phone: string
  SSNLast4: string
  Gender: string
  DivisionID: number
  DivisionName: string
  SubsetHandle: string
  Suffix: string
}

interface Dependent {
  FirstName: string
  Honorific: string
  ID: number
  LastName: string
  MiddleName: string
  ActivationDate: string
  DeactivationDate: string
  RelationshipDescription: string
  Dob: string
}

interface EmployerMembership {
  ActivationDate: string
  CreatedAt: string
  DeactivationDate?: string
  DeletedAt?: string
  Dependents: Dependent[]
  EmployerId: number
  EmployerName: string
  ID: number
  IntegrationId: string
  IsCurrentlyActive: boolean
  LastProcessedMD5: string
  MemberId: number
  Problematic: boolean
  Subscriber: any
  UpdatedAt: string
  CarrierName: string
  PlanName: string
}

export const MemberDetail = ({
  match,
  history,
  location,
}: MemberDetailProps) => {
  // Example of how to import redux state into component
  // Subscribe to data and get access to dispatch
  const [{ member }, dispatch]: any = useStateValue()
  const [loading] = useState(false)
  const [terminatingMembership, setTerminatingMembership] = useState(false)
  const { selectedEmployerID } = useAppPerms()

  // Get access to actions to change data in redux
  const { getMember, termMembership } = memberActions

  const { params } = match
  const { id, employerID } = params

  useEffect(() => {
    getMember(id, employerID, dispatch)
  }, [id])

  if (!member) {
    return (
      <RootComponent>
        <Grid>
          <CircularProgress />
        </Grid>
      </RootComponent>
    )
  }
  const { EmployerMemberships } = member

  const currentMembership = () => {
    return EmployerMemberships
      ? EmployerMemberships.find((item: any) => {
          return item.EmployerId === selectedEmployerID
        })
      : undefined
  }
  const curMembership = currentMembership()

  const handleTermMembership = () => {
    setTerminatingMembership(true)
  }

  const confirmTermMembership = async (termParams: TermParams) => {
    await termMembership(curMembership.ID, termParams, dispatch)
    // effectively refresh the page to ensure dependents show updated term dates
    await getMember(id, employerID, dispatch)
    setTerminatingMembership(false)
  }

  const doRefreshMember = () => {
    getMember(id, employerID, dispatch)
  }

  const notYetActive =
    !curMembership?.IsCurrentlyActive && isNullDate(curMembership?.EndDate)

  return (
    <RootComponent>
      <Helmet titleTemplate={TITLE_TEMPLATE}>
        <title>Member Detail</title>
      </Helmet>
      <ScrollToTop location={location} />
      <Fade in={!loading}>
        <MemberContent>
          <Grid container spacing={2}>
            <MembershipColumn item xs={6} sm={6}>
              {curMembership !== undefined && (
                <MembershipDetail
                  member={member}
                  membership={curMembership}
                  history={history}
                  termMembership={handleTermMembership}
                  doRefreshMember={doRefreshMember}
                />
              )}
            </MembershipColumn>
            <IDCardColumn item xs={6} sm={4}>
              {curMembership !== undefined && (
                <div>
                  <MembershipHeader
                    title="Member ID Card"
                    subheader="This can be printed and given to the member"
                  />
                  <PrintMemberIdCard
                    FirstName={member.FirstName}
                    LastName={member.LastName}
                    MiddleName={member.MiddleName}
                    Suffix={member.Suffix}
                    Dob={member.Dob}
                    QuestID={curMembership.QuestID}
                    HasQuest={curMembership.HasQuest}
                    IntegrationId={curMembership.IntegrationId}
                    IsCurrentlyActive={curMembership.IsCurrentlyActive}
                    NotYetActive={notYetActive}
                  />
                  <DialogSetDeactivationDate
                    key={member.ID}
                    deactivationDate={curMembership.DeactivationDate}
                    skipUpdateUntil={curMembership.SkipUpdateUntil}
                    dependents={curMembership.Dependents}
                    open={terminatingMembership}
                    handleSave={confirmTermMembership}
                    handleClose={() => setTerminatingMembership(false)}
                    showSkipUpdateStep={curMembership.SubsetHandle !== 'manual'}
                  />
                </div>
              )}
            </IDCardColumn>
          </Grid>
        </MemberContent>
      </Fade>
    </RootComponent>
  )
}

interface MembershipDetailProps {
  membership: EmployerMembership
  member: Member
  history: any
  termMembership: any
  doRefreshMember: any
}

const MembershipDetail = ({
  member,
  membership,
  history,
  termMembership,
  doRefreshMember,
}: MembershipDetailProps) => {
  const { userPermissions } = useAppPerms()
  const employerID = useEmployerIDFromRoute()
  const isSubscriber = !(membership.Subscriber?.ID > 0)
  const [toggleEditMemberDetailsDialog, setToggleEditMemberDetailsDialog] =
    useState(false)
  const [subsetHandles, setSubsetHandles] = useState([])

  useEffect(() => {
    if (!employerID) return
    const fetchSubsetHandles = async () => {
      const handles = await getEligibilitySubsetHandles(Number(employerID), {})
      setSubsetHandles(handles)
    }
    fetchSubsetHandles()
  }, [employerID])

  const handleAddDependent = () => {
    history.push({
      pathname: `/employer/${employerID}/eligibility/add-dependent`,
      state: {
        subscriber: {
          EmployerMemberID: membership.ID,
          FirstName: member.FirstName,
          LastName: member.LastName,
          AddressFromMemberID: member.ID,
          CarrierName: membership.CarrierName,
          PlanName: membership.PlanName,
        },
      },
    })
  }

  const handleBtnEditMemberDeets = () => {
    setToggleEditMemberDetailsDialog(true)
  }

  const formatHandle = (string: string) => {
    const result = subsetHandles.filter((handle) => handle.Handle === string)[0]
    return result ? result.EmployerDescription : ''
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <MembershipHeader
            title={nameFormatter(
              member.FirstName,
              member.MiddleName,
              member.LastName,
              member.Suffix
            )}
            subheader={moment(member.Dob).utc().calendar()}
          />
        </Grid>
        <Grid item>
          {userPermissions.UpdateEligibility && (
            <EditButton onClick={handleBtnEditMemberDeets} variant="outlined">
              Edit Details
            </EditButton>
          )}
        </Grid>
      </Grid>
      <MembershipCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemText
                  secondary={member.AddressId && member.Address.DisplayAddress}
                  primary="Address"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={member.Phone ? phoneFormatter(member.Phone) : '-'}
                  primary={<span>Phone Number</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={
                    member.Email ? emailLinkFormatter(member.Email) : '-'
                  }
                  primary={<span>Email</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={member.SSNLast4}
                  primary="Last 4 SSN"
                />
              </ListItem>
              <ListItem>
                <ListItemText secondary={member.Gender} primary="Gender" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={5}>
            {membership.Dependents?.length > 0 && (
              <div>
                <RelatedMembersList
                  related={membership.Dependents}
                  label="Dependents"
                  history={history}
                />
              </div>
            )}
            {membership.Subscriber.ID >
              0 /* subscriber is always singular, so convert to array here to play nice with the .map */ && (
              <div>
                <RelatedMembersList
                  related={[membership.Subscriber]}
                  showRelationship={false}
                  label="Subscriber/Employee"
                  history={history}
                />
              </div>
            )}
            {userPermissions.UpdateEligibility && isSubscriber && (
              <Button onClick={handleAddDependent} variant="outlined">
                Add Dependent
              </Button>
            )}
          </Grid>
        </Grid>
      </MembershipCard>
      <MembershipHeader
        title={membership.EmployerName}
        subheader={membership.IntegrationId}
      />
      <MembershipCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemText
                  secondary={defaultDateFormat(membership.ActivationDate)}
                  primary={<span>Activation Date</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={membership.CarrierName}
                  primary={<span>Carrier Name</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={membership.PlanName}
                  primary={<span>Plan Name</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={formatHandle(member.SubsetHandle)}
                  primary="Employer Group"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={member.DivisionName}
                  primary="Division"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={5}>
            <List>
              <ListItem>
                <ListItemText
                  secondary={defaultDateFormat(membership.DeactivationDate)}
                  primary={
                    <span>
                      Deactivation Date
                      <Tooltip
                        title="Deactivation date is defined as the first date of non-coverage."
                        style={{
                          marginLeft: '5px',
                          marginTop: '-3px',
                          color: 'gray',
                        }}
                      />
                    </span>
                  }
                />
              </ListItem>
              {userPermissions.UpdateEligibility && (
                <ListItem style={{ display: 'block' }}>
                  <Button
                    onClick={termMembership}
                    variant="contained"
                    color="primary">
                    Modify deactivation date
                  </Button>
                  <div style={{ marginTop: '8px' }}>
                    <Typography variant="caption">
                      <i>
                        Note: You can use this functionality to re-activate a
                        member who has been deactivated
                      </i>
                    </Typography>
                  </div>
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </MembershipCard>

      {!employerID ? null : (
        <EditMemberDetailsDialog
          memberID={member.ID}
          isOpen={toggleEditMemberDetailsDialog}
          onClose={() => {
            setToggleEditMemberDetailsDialog(false)
          }}
          onUpdated={doRefreshMember}
          employerID={Number(employerID)}
        />
      )}
    </>
  )
}

interface RelatedMembersListProps {
  related: Dependent[]
  label: string
  history: any
  showRelationship?: boolean
}

interface SecondaryTextProps {
  dob: string
  activation: string
  deactivation: string
  relationship: string
}

const RelatedMembersList = ({
  related,
  label,
  history,
  showRelationship = true,
}: RelatedMembersListProps) => {
  const employerID = useEmployerIDFromRoute()
  const handleMemberClick = (id: number) => {
    return () => {
      history.push(`/employer/${employerID}/member/${id}`)
    }
  }

  const SecondaryText = (props: SecondaryTextProps) => {
    const calcAge = (dob: any) => {
      const epoch = 1970
      let ageDate = new Date(dob)
      const diff = Date.now() - ageDate.getTime()
      const age = Math.abs(new Date(diff).getUTCFullYear() - epoch)

      return age > 0 ? age : 'Infant'
    }

    const fmt = 'MM/DD/YYYY'

    const deactivationDate =
      !props.deactivation ||
      moment(props.deactivation).isSame(moment('0001-01-01'))
        ? 'N/A'
        : moment(props.deactivation).format(fmt)

    const active =
      deactivationDate === 'N/A' ? true : moment().isBefore(props.deactivation)

    return (
      <>
        {showRelationship && (
          <p style={{ margin: '0px' }}>{props.relationship}</p>
        )}
        <p style={{ margin: '0px' }}>Age: {calcAge(props.dob)}</p>
        <p style={{ margin: '0px' }}>DOB: {props.dob}</p>
        <p style={{ margin: '0px' }}>
          Activation: {moment(props.activation).format(fmt)}
        </p>
        <p style={{ margin: '0px', color: active ? '#0000008a' : 'firebrick' }}>
          Deactivation: {deactivationDate}
        </p>
      </>
    )
  }

  return (
    <List subheader={<ListSubheader>{label}</ListSubheader>}>
      {related.map((rel) => {
        const name = `${rel.FirstName} ${rel.LastName}`
        const dob = moment(rel.Dob).utc().calendar()
        return (
          <MemberListItem
            button
            onClick={handleMemberClick(rel.ID)}
            key={rel.ID}>
            <ListItemText
              primary={name}
              secondary={
                <SecondaryText
                  dob={dob}
                  relationship={rel.RelationshipDescription}
                  activation={rel.ActivationDate}
                  deactivation={rel.DeactivationDate}
                />
              }
            />
          </MemberListItem>
        )
      })}
    </List>
  )
}
